import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { filterDialogOptions, FilterOptions } from 'src/app/shared/interfaces/common.interface';
import { ParamsOptions } from 'src/app/shared/interfaces/pagination-options';
import { HttpService } from 'src/app/shared/services/http.service';
/**
 * Service for managing and applying filters in the application.
 */
@Injectable({
  providedIn: 'root',
})
export class FilterService {
  /**
   * Subject to hold and emit the filter options.
   */
  filterOptions: BehaviorSubject<Array<FilterOptions>> = new BehaviorSubject<Array<FilterOptions>>([]);
  /**
   * Object to store the currently applied filters.
   */
  appliedFilters = {};
  /**
   * Flag to indicate if an API call is currently in progress.
   */
  apiIsProcessing = false;

  /**
   * Creates an instance of FilterService.
   *
   * @param apiUrl - The base URL for the API.
   * @param apiVersion - The version of the API.
   * @param _httpService - Service for making HTTP requests.
   * @param route - The ActivatedRoute used for accessing route parameters.
   * @param dialog - Service for managing dialogs.
   */
  constructor(
    @Inject('API_BASE') private apiUrl: string,
    @Inject('API_VERSION') private apiVersion,
    private _httpService: HttpService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  /**
   * Fetches filter options for a specific column from the API.
   *
   * @param tableName - The name of the table from which to fetch options.
   * @param columnName - The name of the column for which to fetch options.
   * @param params - Optional parameters to pass with the request.
   * @param flag - Optional flag to modify the request URL.
   * @returns An Observable of the filter options.
   */
  fetchOptionsByColumn(tableName: string, columnName: string, params?, flag = false) {
    const type = this.route.snapshot.queryParamMap.get('clientId') ? 'client' : 'system';
    const url = `${this.apiUrl}/${this.apiVersion}/${tableName}/${!flag ? `${type}/` : ``}column/${columnName}`;
    if (!params.filter) {
      delete params.filter;
    } else {
      params = this.removeParamsByKey(columnName, params);
    }
    if (!params.sort) {
      delete params.sort;
    }
    if (!params.search) {
      delete params.search;
    }
    delete params.limit;
    delete params.page;
    return this._httpService.get(url, params);
  }

  /**
   * Removes a specific key from the filter parameters.
   *
   * @param key - The key to remove from the parameters.
   * @param params - The filter parameters object.
   * @param separator - Optional separator for the filter values.
   * @returns The updated parameters object.
   */
  removeParamsByKey(key: string, params: ParamsOptions, separator?) {
    const zero = 0;
    const filter = params?.filter?.split(separator || ',');
    const item = filter?.find((ele) => ele.includes(key));
    if (item) {
      const index = filter?.findIndex((ele) => ele == item);
      if (index != undefined) {
        filter?.splice(index, 1);
        if (filter?.length !== undefined) {
          filter?.length > zero ? (params.filter = filter?.join(separator || ',')) : delete params.filter;
        }
      }
    }
    return params;
  }

  /**
   * Fetches options for a specific column and updates the filter options.
   *
   * @param tableName - The name of the table from which to fetch options.
   * @param columnName - The name of the column for which to fetch options.
   * @param params - Parameters to pass with the request.
   * @param dialog - Optional dialog reference to update with the response data.
   * @param flag - Optional flag to modify the request URL.
   */
  getOptionsByColumn(
    tableName: string,
    columnName: string,
    params: ParamsOptions,
    dialog?: MatDialogRef<FilterComponent>,
    flag?,
  ) {
    this.apiIsProcessing = true;
    this.fetchOptionsByColumn(tableName, columnName, params, flag).subscribe({
      next: (response) => {
        if (response) {
          this.apiIsProcessing = false;
          if (dialog) {
            dialog.componentInstance.searchList = response && response.data ? response.data : response;
          } else {
            this.filterOptions.next(response && response.data ? response.data : response);
          }
        }
      },
      error: (error) => {
        if (error) {
          this.apiIsProcessing = false;
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Opens a dialog for filtering options.
   *
   * @param $event - The event that triggered the dialog opening. Used for positioning.
   * @returns The reference to the opened dialog.
   */
  openSearchFilterDialog($event): MatDialogRef<FilterComponent, filterDialogOptions > {
    this.dialog.closeAll();
    const modalWidth = 500;
    const dialog = this.dialog.open(FilterComponent, {
      data: $event,
      hasBackdrop: true,
      disableClose: false,
      position: {
        left:
          document.body.clientWidth - $event.pageX > modalWidth
            ? $event.pageX + 'px'
            : document.body.clientWidth - modalWidth - 100 + 'px',
        top: $event.pageY + 7 + 'px',
      },
    });
    return dialog;
  }

  /**
   * Toggles the active class for a column based on its filters.
   *
   * @param columns - The array of column definitions.
   * @param key - The key of the column to toggle.
   */
  toggleColumnActiveClass(columns, key: string) {
    const index = columns.findIndex((ele) => ele.key === key);
    const column = columns[index];
    const filterO = this.getAppliedFiltersByKey(column.filterKey || column.key);
    if (index >= 0 && column.hasOwnProperty('search')) {
      column.search.active = filterO && filterO.id ? true : false;
    }
    if (index >= 0 && column.hasOwnProperty('filter')) {
      column.filter.active = filterO && filterO.length > 0 ? true : false;
    }
  }

  /**
   * Resets all applied filters.
   */
  resetFilters() {
    this.appliedFilters = {};
  }

  /**
   * Sets a filter as applied.
   *
   * @param key - The key of the filter.
   * @param value - The filter options.
   */
  setAppliedFilters(key: string, value: FilterOptions) {
    this.appliedFilters[key] = value;
  }

  /**
   * Gets all currently applied filters.
   *
   * @returns An object containing all applied filters.
   */
  getAppliedFilters() {
    return this.appliedFilters;
  }

  /**
   * Gets the filter applied for a specific key.
   *
   * @param key - The key of the filter.
   * @returns The filter options for the specified key.
   */
  getAppliedFiltersByKey(key: string) {
    return this.appliedFilters[key];
  }

  /**
   * Constructs a parameter string from the applied filters.
   *
   * @param separator - Optional separator for the filter values.
   * @returns A string representing the applied filters.
   */
  getAppliedFiltersParamString(separator?: string) {
    let paramString = '';
    Object.keys(this.getAppliedFilters()).forEach((oKey: string) => {
      const values = this.getAppliedFiltersByKey(oKey);
      if (paramString) {
        paramString = `${paramString},`;
      }
      if (Array.isArray(values)) {
        paramString = paramString + (values?.length ? `${oKey}|${values.map((o) => o.id).join(separator || ':')}` : '');
      } else {
        paramString = paramString + (values?.id ? `${oKey}|${values?.id}` : `${oKey}|${values}`);
      }
    });
    return paramString;
  }

  /**
   * Removes a specific filter by key.
   *
   * @param key - The key of the filter to remove.
   */
  removeFiltersByKey(key: string) {
    delete this.appliedFilters[key];
  }
}


