import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPatternValidatorDirective } from 'src/app/shared/directives/custom-pattern-validator.directive';
import { DigitOnlyDirective } from 'src/app/shared/directives/digit-only.directive';
import { LoadedIfSignedInDirective } from 'src/app/shared/directives/loaded-if-signed-in.directive';
import { OnErrorImgDirective } from 'src/app/shared/directives/on-error-img.directive';
import { OnlyNumberDirective } from 'src/app/shared/directives/only-number.directive';
import { TrimDirective } from 'src/app/shared/directives/trim.directive';
import { DobComponent } from '../../components/dob/dob.component';
import { ClickOutSideDirective } from '../../directives/click-out-side.directive';



@NgModule({
  declarations: [
    OnlyNumberDirective,
    DigitOnlyDirective,
    OnErrorImgDirective,
    CustomPatternValidatorDirective,
    LoadedIfSignedInDirective,
    OnErrorImgDirective,
    DigitOnlyDirective,
    DobComponent,
    TrimDirective,
    ClickOutSideDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    OnlyNumberDirective,
    DigitOnlyDirective,
    OnErrorImgDirective,
    CustomPatternValidatorDirective,
    LoadedIfSignedInDirective,
    OnErrorImgDirective,
    DigitOnlyDirective,
    DobComponent,
    TrimDirective,
    ClickOutSideDirective
  ]
})
export class DirectiveModule { }
