import { Pipe, PipeTransform } from '@angular/core';
import { ClientsService } from 'src/app/modules/clients/clients.service';

/**
 * Pipe for transforming client-related labels.
 * This pipe uses the `ClientsService` to get client labels and transform
 * specific values to their respective labels based on predefined criteria.
 */
@Pipe({
  name: 'clientLabel',
})
export class ClientLabelPipe implements PipeTransform {
  /**
   * Creates an instance of ClientLabelPipe.
   * @param _clientsService The service used to fetch client labels.
   */
  constructor(private _clientsService: ClientsService) {}

  /**
   * Transforms the input value to a client label.
   * Checks if the input value is 'managed' or 'manager' and replaces
   * it with the corresponding label fetched from `ClientsService`. If
   * the input value does not match these criteria, it returns the
   * original value.
   *
   * @param value The input value to transform.
   * @returns The transformed label or the original value if no match is found.
   */
  transform(value: string): unknown {
    const clientLabel = this._clientsService.getClientLabel();
    if (value && value.toLowerCase() === 'managed') {
      value = clientLabel && clientLabel.managedUser ? clientLabel.managedUser : value;
    }
    if (value && value.toLowerCase() === 'manager') {
      value = clientLabel && clientLabel.managerUser ? clientLabel.managerUser : value;
    }
    return value;
  }
}
