<div *ngIf="showSearch" class="search_dialog modal-xs">
  <div class="flex-between dialog-header">
    <h2 class="mb-0">Search</h2>
    <div class="dialog-header-icons">
      <i-feather name="rotate-ccw" [ngClass]="{ disabled: !searchKey }" mat-dialog-close (click)="remove()"></i-feather>
      <i-feather name="x" mat-dialog-close></i-feather>
    </div>
  </div>
  <div class="form-group search-input search-100">
    <label for="" class="form-label">{{ title }}</label>
    <input type="text" class="form-control" #searchEvent [(ngModel)]="searchKey" placeholder="Search" />
    <i-feather name="search" (click)="search()" class="feather-20 text-disabled" *ngIf="!searchKey"></i-feather>
    <div class="input-search-spinner" *ngIf="searchKey && getAPIProcessingFlag()">
      <mat-spinner [diameter]="18"></mat-spinner>
    </div>
  </div>
  <mat-dialog-content>
    <ul class="list-group mt-n2" *ngIf="!getAPIProcessingFlag() && searchList && searchList.length > 0">
      <li class="searchList pointer" *ngFor="let item of searchList" (click)="selectedOption(item)">
        <!--  Condition added by Anjum bcz user search by api return key value in userName -->
        <!--  other api return key value in name -->
        <span *ngIf="data.column.displayKey !== 'userName' && data.column.title !== 'Username'">
          {{ item?.name }}
        </span>
        <span *ngIf="data.column.displayKey === 'userName' && data.column.title === 'Username'">
          {{ item?.userName }}
        </span>
      </li>
    </ul>
    <ul class="list-group mt-n2" *ngIf="searchKey && !getAPIProcessingFlag() && searchList && searchList.length <= 0">
      <li class="searchList pointer">No record found</li>
    </ul>
  </mat-dialog-content>
</div>
<!-- filter options-->
<div class="filter_dialog modal-xs" *ngIf="showFilter">
  <div class="flex-between dialog-header">
    <h2 class="mb-0">Filter</h2>
    <div class="dialog-header-icons">
      <i-feather name="check" (click)="save()"></i-feather>
      <i-feather name="rotate-ccw" mat-dialog-close (click)="remove()"></i-feather>
      <i-feather name="x" mat-dialog-close></i-feather>
    </div>
  </div>

  <form [formGroup]="checkBoxForm">
    <div class="form-group search-input search-100 mt-1">
      <input type="text" #searchEvent class="form-control" placeholder="Filter text" />
      <i-feather name="search" class="text-disabled"></i-feather>
    </div>

    <mat-dialog-content>
      <div *ngIf="filterOptions && getAPIProcessingFlag()" class="filter-spinner">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
      <div *ngIf="filterOptions && filterOptions.length > 0">
        <div class="form-group form-check">
          <!-- indeterminated: boolean=false; -->
          <mat-checkbox
            (change)="selectAllOptions($event)"
            [(indeterminate)]="indeterminate"
            [checked]="allSelected"
            id="selectAll"
          >
          </mat-checkbox>
          <!-- <input type="checkbox" (change)="selectAllOptions($event)" [checked]="allSelected" id="selectAll" /> -->
          <label for="selectAll"> Select All </label>
        </div>
        <div class="cg-list-group edit-card" formArrayName="filterOptions">
          <div
            class="filter-list"
            *ngFor="let control of options['controls'] | filter: searchEvent.value : true; let i = index"
          >
            <div [formGroup]="control" class="form-group form-check">
              <input type="checkbox" [id]="i" formControlName="checked" (change)="onChangeCheckBox($event)" />
              <label for="i">
                {{ control.value.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-group mt-n2" *ngIf="filterOptions && !getAPIProcessingFlag() && filterOptions.length <= 0">
        <li class="searchList pointer">No record found</li>
      </ul>
    </mat-dialog-content>
  </form>
</div>
