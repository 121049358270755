import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment } from 'src/app/shared/interfaces/common.interface';
import { FileUploadService } from 'src/app/shared/modules/file-upload/file-upload.service';

/**
 * Component for displaying a document preview carousel within a dialog.
 */
@Component({
  selector: 'app-document-preview-carousel',
  templateUrl: './document-preview-carousel.component.html',
  styleUrls: ['./document-preview-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewCarouselComponent implements OnInit {
  /** Index of the currently displayed attachment */
  index = 0;
  /** Flag indicating if there was an error loading the attachment */
  loadingAttachmentError = false;

  /** The currently selected attachment */
  selectedAttachment!: Attachment;

  /**
   * Constructor to inject dependencies and initialize the component.
   * @param change ChangeDetectorRef to manually trigger change detection
   * @param sanitize DomSanitizer to sanitize URLs
   * @param fileUploadService FileUploadService to handle file uploads
   * @param data Data passed to the dialog, including attachments, selected attachment, and title
   */
  public constructor(
    private change: ChangeDetectorRef,
    private sanitize: DomSanitizer,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA)
    public data: { attachments: Attachment[]; selectedAttachment: Attachment; title: string | null | undefined },
  ) {}

  /**
   * OnInit lifecycle hook to initialize the component.
   */
  async ngOnInit(): Promise<void> {
    if (this.data?.selectedAttachment) {
      const selectedAttachmentIndex = (this.index = this.data.attachments.findIndex(
        (attachment: Attachment) => attachment.url === this.data?.selectedAttachment.url,
      ));
      this.index = selectedAttachmentIndex >= 0 ? selectedAttachmentIndex : 0;
      this.selectedAttachment = this.data?.selectedAttachment;
      await this.preview(this.selectedAttachment);
    }
  }

  /**
   * Handler for errors loading the attachment.
   */
  onErrorAttachment() {
    this.loadingAttachmentError = true;
  }

  /**
   * Moves to the previous attachment in the carousel.
   */
  prev() {
    this.index = this.index > 0 ? this.index - 1 : 0;
    this.prevNextClickHandler();
  }

  /**
   * Moves to the next attachment in the carousel.
   */
  next() {
    this.index = this.index < this.data.attachments.length - 1 ? this.index + 1 : this.data.attachments.length - 1;
    this.prevNextClickHandler();
  }

  /**
   * Handles the click event for previous and next buttons to update the preview.
   */
  prevNextClickHandler() {
    if (this.data?.attachments?.length && this.data?.attachments[this.index]?.url) {
      const file = this.data.attachments[this.index];
      this.preview(file);
    }
  }

  /**
   * Handles the click event for thumbnail images to update the preview.
   * @param file Attachment to preview
   */
  onClickThumbnails(file: Attachment) {
    if (file?.url) {
      this.preview(file);
    }
  }

  /**
   * Previews the selected attachment.
   * @param attachment Attachment to preview
   */
  async preview(attachment: Attachment): Promise<void> {
    if (!attachment.safeUrl) {
      attachment.safeUrl = this.sanitize.bypassSecurityTrustResourceUrl(
        attachment && attachment.v2
          ? attachment?.url
          : (await this.fileUploadService.uploadFileAccessSignature(attachment.url))?.url,
      );
    }
    this.selectedAttachment = attachment;
    this.change.markForCheck();
  }

  /**
   * Gets the MIME type of the specified URL.
   * @param url URL of the attachment
   * @returns MIME type of the attachment
   */
  getMimeType(url: string) {
    if (!url) return '';
    const selectedAttachment = this.data.attachments.find((attachment: Attachment) => attachment.url === url);
    return selectedAttachment?.mimeType ? selectedAttachment.mimeType : this.fileUploadService.getMimeType(url);
  }
}
