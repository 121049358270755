import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../authentication.service';
/**
 * Component for handling user login.
 * Provides a form for users to enter their credentials and handles login logic.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /**
   * Minimum length required for the password field.
   */
  MIN_LENGTH_SIZE = 8;

  /**
   * Default language key used for setting the application language.
   */
  defaultLanguageKey = 'en';

  /**
   * Flag to indicate if the form has been submitted.
   */
  submitted = false;

  /**
   * Form group for login form.
   */
  loginForm!: FormGroup;

  /**
   * Constructs an instance of LoginComponent.
   *
   * @param fb - FormBuilder service for creating and managing forms.
   * @param router - Router service for navigation.
   * @param authService - AuthenticationService for handling authentication requests.
   * @param toastrService - ToastrService for displaying notifications.
   */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private toastrService: ToastrService,
  ) {}

  /**
   * Initializes the component by setting up the login form.
   */
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(this.MIN_LENGTH_SIZE)]],
    });
  }

  /**
   * Gets the form controls of the login form.
   *
   * @returns {any} The controls of the login form.
   */
  get loginFormControls() {
    return this.loginForm['controls'];
  }

  /**
   * Submits the login form.
   *
   * If the form is valid, it calls the login method of AuthenticationService to authenticate the user.
   * Displays a success message if the login is successful and navigates to the clients page.
   *
   * @memberof LoginComponent
   */
  submit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(
        (response) => {
          if (response) {
            this.toastrService.success('Login successfully');
            this.loginSuccessHandler(response);
          }
        },
        (error) => {
          if (error) {
            //console.log(error)
          }
        },
      );
    }
  }

  /**
   * Handles the actions to be taken upon successful login.
   *
   * @param {any} response - The response object from the login request.
   */
  loginSuccessHandler(response) {
    if (response) {
      if (response.principal) {
        this.authService.setUserDetails(response.principal);
      }
      this.authService.setTokens(response);
      this.authService.setLanguage(this.defaultLanguageKey);
      this.authService.startRefreshTokenTimer();
      this.router.navigate(['/clients']);
    }
  }
}
