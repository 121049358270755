<div class="page-wrap d-flex flex-row align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <span class="display-4 d-block">404</span>
                <div class="mb-4 lead">The page you are looking for was not found.</div>
                <a [routerLink]="['/clients']" class="btn text-link">Back to Dashboard</a>
            </div>
        </div>
    </div>
</div>