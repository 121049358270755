import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userGrpType',
  standalone: true
})
export class UserGrpTypePipe implements PipeTransform {
// // Member', 'Observer', 'CoOwner
   userGroupTypes: Array<{ value: string; viewValue: string }> = [
    { value: 'Member', viewValue: 'Member' },
    { value: 'CoOwner', viewValue: 'Co-owner' },
    { value: 'Observer', viewValue: 'Observer' },
    { value: 'owner', viewValue: 'Owner' },
  ];

  transform(value: unknown, ...args: unknown[]): unknown {
    const index=this.userGroupTypes.findIndex(ele=>ele.value===value);

    
    return this.userGroupTypes[index].viewValue;
  }

}
