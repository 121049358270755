<div class="dialog-sm" *ngIf="communicationDetails">
    <div class="flex-between communication-dialog-header">
        <h2 class="mb-0">{{communicationDetails.title}}</h2>
        <div class="communication-card-icons">
            <i-feather name="edit-2" class="feather-20 pointer mx-3" (click)="popupClose()"></i-feather>
            <i-feather name="x" class="feather-20 pointer" mat-dialog-close></i-feather>
        </div>
    </div>
    <div class="additional-alert">
        <i-feather name="info" class="feather-16 pointer"></i-feather>
        <p class="mb-0">{{communicationDetails.info}}
        </p>
    </div>
    <mat-dialog-content>
        <div class="communication-dialog" *ngIf="communicationDetails.channels.email">
            <div class="communication-card-header">
                <h2>Email</h2>
            </div>
            <div class="communication-card-content">
                <div class="communication-card-content-item">
                    <div class="communication-card-label">Subject:</div><span class="email-sub"
                        [innerHTML]="communicationDetails.email.subject"></span>
                </div>
                <div class="communication-card-content-item">
                    <div class="communication-card-label">Body:</div>
                    <div [innerHTML]="communicationDetails.email.body">
                    </div>
                </div>
            </div>
        </div>
        <div class="communication-dialog" *ngIf="communicationDetails.channels.sms">
            <div class="communication-card-header">
                <h2>SMS</h2>
            </div>
            <div class="communication-card-content">
                <div class="communication-card-content-item">
                    <p [innerHTML]="communicationDetails.sms.body"></p>
                </div>
            </div>
        </div>

        <div class="communication-dialog" *ngIf="communicationDetails.channels.push">
            <div class="communication-card-header">
                <h2>Push</h2>
            </div>
            <div class="communication-card-content">
                <div class="communication-card-content-item">
                    <p [innerHTML]="communicationDetails.push.body"></p>
                </div>
            </div>
        </div>
        <div class="communication-dialog" *ngIf="communicationDetails.channels.inApp">
            <div class="communication-card-header">
                <h2>In App</h2>
            </div>
            <div class="communication-card-content">
                <div class="communication-card-content-item">
                    <span [innerHTML]="communicationDetails.inApp.body"></span>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>