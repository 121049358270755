import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 * Pipe for filtering a list of items based on a specified filter string.
 *
 * This pipe transforms an array of items by filtering them according to a
 * filter string. It can handle both plain objects and `FormGroup` instances
 * depending on the `isformGrp` flag.
 */
@Pipe({
  name: 'filter',
  pure: true,
})
export class FilterPipe implements PipeTransform {
  /**
   * Filters the input array of items based on the provided filter string.
   *
   * If the `isformGrp` flag is set to `true`, the pipe expects the items to
   * be instances of `FormGroup` and will filter based on the `name` field of
   * the `FormGroup` value. Otherwise, it will filter based on the `userName`
   * or `name` properties of the items.
   *
   * @param items The array of items to filter. This can be either an array
   * of objects with `userName` or `name` properties, or an array of `FormGroup`
   * instances.
   * @param filterData The string used for filtering the items. It is converted
   * to lowercase to perform a case-insensitive comparison.
   * @param isformGrp A boolean flag indicating whether the items are `FormGroup`
   * instances. If `true`, the pipe will filter based on the `name` property
   * of the `FormGroup` value.
   * @returns A new array containing only the items that match the filter criteria.
   * Returns the original array if no filterData is provided or if the array is empty.
   */
  transform(items: any[], filterData: string, isformGrp = false): any[] {
    if (!items) {
      items = [];
      return items;
    }
    if (!filterData) {
      return items;
    }
    filterData = filterData.toString().toLowerCase();

    if (isformGrp) {
      const temp: Array<FormGroup> = items as Array<FormGroup>;
      return temp.filter((ele) => {
        return ele.value.name.toLowerCase().includes(filterData);
      });
    } else {
      return items.filter((it: any) => {
        if (it?.userName) {
          return it?.userName.toLowerCase().includes(filterData);
        }
        if (it?.name) {
          return it?.name.toLowerCase().includes(filterData);
        }
      });
    }
  }
}
