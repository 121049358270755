import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GenericTableColumn } from '../../interfaces/generic-table';
/**
 * A generic table component for displaying tabular data with support for sorting, pagination, filtering, and row actions.
 */
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  /**
   * The data to be displayed in the table.
   */
  @Input() data;
  /**
   * The columns configuration for the table.
   */
  @Input() columns;
  /**
   * The columns to be displayed in the table.
   */
  @Input() displayedColumns;
  /**
   * Pagination options including limit, current page, and total count.
   */
  @Input() paginationOptions;
  /**
   * Flag indicating if table rows are clickable.
   */
  @Input() isRowClickable = false;
  /**
   * Flag indicating if checkboxes should be shown in the table.
   */
  @Input() showCheckbox = false;
  /**
   * Flag indicating if checkboxes should be grouped by ID.
   */
  @Input() checkGroupId = false;
  /**
   * The table rendering configuration.
   */
  @Input() tableRender;
  /**
   * Label to be displayed when no data is available.
   */
  @Input() noDataLabel = 'No record found';
  /**
   * Event emitted when the table needs to be refetched.
   */

  @Output() refetch: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  /**
   * Event emitted when an item needs to be deleted by ID.
   */
  @Output() delete: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Event emitted to redirect to a different route by ID.
   */
  @Output() redirect: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Event emitted to sort the table by a specific column and order.
   */
  @Output() sort: EventEmitter<{ column: string; order: string }> = new EventEmitter<{
    column: string;
    order: string;
  }>();
  /**
   * Event emitted when an action item is clicked.
   */
  @Output() clickOnActionItems: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Event emitted when a user is selected with checkbox.
   */
  @Output() selectedUser: EventEmitter<object> = new EventEmitter<object>();
  /**
   * Event emitted when a view action is triggered.
   */
  @Output() view: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Event emitted when a search action is triggered.
   */
  @Output() search: EventEmitter<FilterColumnOption> = new EventEmitter<FilterColumnOption>();
  /**
   * Event emitted when a filter action is triggered.
   */
  @Output() filter: EventEmitter<FilterColumnOption> = new EventEmitter<FilterColumnOption>();
  /**
   * Angular Material paginator component for handling pagination.
   */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  /**
   * Data source for the table, used to manage and display data.
   */
  dataSource!: MatTableDataSource<any>;
  /**
   * Array of page size options for pagination.
   */
  pageSizeOptions: number[] = [5, 10, 15, 20];
  /**
   * The event object for pagination changes.
   */
  pageEvent!: PageEvent;
  /**
   * Array of IDs for checked items.
   */
  uIdChecked = [];

  /** Creates an instance of the class.
   * @param router - Angular Router instance for navigation.
   */
  constructor(private router: Router) {}
  /**
   * Updates the data source when input properties change.
   */
  ngOnChanges(): void {
    this.dataSource = this.data;
  }
  /**
   * Emits a sort event when a column is sorted.
   * @param column - The column to sort by.
   * @param order - The sort order (ascending or descending).
   */
  sortByColumn(column: string, order: string) {
    const sortO = { column, order };
    this.sort.emit(sortO);
    this.appliedSortOnColumn(sortO);
  }
  /**
   * Applies the sort configuration to the columns.
   * @param sortO - The sorting options including column and order.
   */
  appliedSortOnColumn(sortO) {
    this.columns.filter((column) => {
      column.active = column.key === sortO.column ? true : false;
      column.order = column.key === sortO.column ? sortO.order : '';
      return column;
    });
  }
  /**
   * Emits an event when the checkbox state is changed.
   * @param id - The ID of the item.
   * @param status - The checkbox state.
   */
  changeChkState(id: string, status) {
    this.selectedUser.emit({
      id: id,
      checkBoxClickStatus: status.target.checked,
    });
  }
  /**
   * Emits a delete event with the ID of the item to be deleted.
   * @param e - The ID of the item to delete.
   */
  deleteById(e: string) {
    this.delete.emit(e);
  }
  /**
   * Emits a view event with the ID of the item to view.
   * @param e - The ID of the item to view.
   */
  viewById(e: string) {
    this.view.emit(e);
  }
  /**
   * Handles pagination changes and emits a refetch event with updated pagination options.
   * @param e - The pagination event.
   */
  handlePagination(e) {
    this.refetch.emit(this.paginationOptions);
    this.paginationOptions = {
      limit: this.paginationOptions.limit,
      currentPage: this.paginationOptions.currentPage,
      totalCount: this.paginationOptions.totalCount,
    };
  }
  /**
   * Redirects to a different route if the row is clickable and has an ID.
   * @param row - The row data.
   */
  onRowClick(row) {
    if (this.isRowClickable && row) {
      if (row?.id?.value) {
        this.redirect.emit(row.id.value);
      }
    }
  }
  /**
   * Emits a filter event with filter options when a filter action is triggered.
   * @param $event - The click event.
   * @param column - The column to filter by.
   */
  clickOnFilter($event, column: GenericTableColumn) {
    const filterOptions = {
      column: column,
      pageX: $event.pageX,
      pageY: $event.pageY,
      type: 'filter',
      title: column.title || '',
    };
    this.filter.emit(filterOptions);
  }
  /**
   * Emits a search event with search options when a search action is triggered.
   * @param $event - The click event.
   * @param column - The column to search by.
   */
  clickOnSearch($event, column: GenericTableColumn) {
    const searchOptions = {
      column: column,
      pageX: $event.pageX,
      pageY: $event.pageY,
      type: 'search',
      title: column.title || '',
    };
    this.search.emit(searchOptions);
  }
  /**
   * Handles action icon clicks and emits corresponding events.
   * @param $event - The click event.
   * @param icon - The action icon.
   * @param row - The row data.
   */
  clickOnActionIcons($event: MouseEvent, icon, row) {
    $event.stopPropagation();
    if (icon && row && row?.id?.value) {
      if (icon.key === 'delete') {
        this.deleteById(row.id.value);
      } else if (icon.key === 'userStatus') {
        this.clickOnActionItems.emit(row);
      } else if (row.action === 'client') {
        this.redirect.emit(row.id.value);
      } else if (icon.action === 'viewSamePage' && icon.route === '') {
        this.view.emit(row.id.value);
      } else if (icon.action === 'EditWidget' && icon.route === '') {
        this.view.emit(row.id.value);
      } else {
        if (icon.route) {
          this.router.navigate([icon.route], {
            queryParams: row.id.queryParams,
            queryParamsHandling: 'merge',
            fragment: row.id?.fragment || ''
          });
        } else {
          this.view.emit(row.id.value);
        }
      }
    }
  }
}
/**
 * Interface for filter column options used in table filtering.
 */
export interface FilterColumnOption {
  /**
   * The column to be filtered.
   */
  column: GenericTableColumn;
  /**
   * The X position of the click event for the filter.
   */
  pageX: string;
  /**
   * The Y position of the click event for the filter.
   */
  pageY: string;
  /**
   * The type of filter action (e.g., 'filter', 'search').
   */
  type: string;
}
