import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
/**
 * A component that provides a typeahead search functionality. It emits search keywords, item selections, and item removals.
 */
@Component({
  selector: 'app-typeahead-search',
  templateUrl: './typeahead-search.component.html',
  styleUrls: ['./typeahead-search.component.scss'],
})
export class TypeaheadSearchComponent implements AfterViewInit, OnDestroy {
  /**
   * Label for the search input field.
   */
  @Input() label = '';
  /**
   * Placeholder text for the search input field.
   */
  @Input() placeholder = 'Type here to search';
  /**
   * Data to be used for the typeahead search suggestions.
   */
  @Input() data: any = null;
  /**
   * List of selected items.
   */
  @Input() selectedItems: any = [];
  /**
   * Form instance for the component.
   */
  @Input() form!: any;
  /**
   * The name of the form control.
   */
  @Input() formControlName = '';
  /**
   * Key for the form control.
   */
  @Input() formControlKey = '';
  /**
   * Indicates whether selected chips should be displayed.
   */
  @Input() isShowSelectedChips = false;
  /**
   * Indicates whether the typeahead search box should be shown.
   */
  @Input() showTypeSearchBox = true;
  /**
   * Indicates whether the search is specific to medications.
   */
  @Input() isMedicationSearch = false;
  /**
   * Indicates whether the form has been submitted.
   */
  @Input() isSubmitted = false;
  /**
   * Event emitted when a search is performed.
   */
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Event emitted when an item is selected.
   */
  @Output() selection: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Event emitted when a selected item is removed.
   */
  @Output() remove: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Subscription for keyup events from the search input field.
   */
  keyupSubscription!: Subscription;
  /**
   * Reference to the search input field element.
   */
  @ViewChild('typeaheadsearch') typeaheadsearch!: ElementRef;

  /**
   * Constructs the component.
   */
  constructor() {}
  /**
   * Initializes the keyup event binding after the view has been initialized.
   */
  ngAfterViewInit() {
    this.bindKeyUpEvent();
  }
  /**
   * Binds the keyup event to the search input field and handles search logic.
   * Emits search keywords or resets data based on the input value.
   */
  bindKeyUpEvent() {
    if (this?.typeaheadsearch?.nativeElement) {
      this.keyupSubscription = fromEvent(this.typeaheadsearch.nativeElement, 'keyup')
        .pipe(
          debounceTime(500),
          map((event: any) => (<HTMLInputElement>event.target).value),
          filter((keywords: string) => keywords.length >= 3 || keywords.length === 0),
          distinctUntilChanged(),
        )
        .subscribe((keyword) => {
          if (keyword) {
            this.search.emit(keyword);
          } else {
            this.resetData();
          }
        });
    }
  }
  /**
   * Emits the selected item.
   *
   * @param item - The item to be selected.
   */
  onSelectItem(item: any) {
    this.selection.emit(item);
  }
  /**
   * Emits the removal of a selected item.
   *
   * @param item - The item to be removed.
   */
  removeSelectedItem(item: string) {
    this.remove.emit(item);
  }
  /**
   * Resets the search data to an empty array.
   */
  resetData() {
    this.data = [];
  }
  /**
   * Checks if the given value is an array.
   *
   * @param array - The value to check.
   *
   * @returns `true` if the value is an array; otherwise, `false`.
   */
  isArray(array: any) {
    return array ? Array.isArray(array) : false;
  }
  /**
   * Unsubscribes from keyup events to prevent memory leaks when the component is destroyed.
   */
  ngOnDestroy() {
    this.keyupSubscription?.unsubscribe();
  }
}
