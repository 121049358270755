<div class="auth-wrapper p-3">
  <div class="logo text-center">
    <img src="assets/images/logo/medocity_logo.png" alt="Medocity logo" />
  </div>
  <div class="auth-container bg-white radius-md">
    <h1>{{'resetPassword' | translate}}</h1>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()">
      <div class="mb-3">
        <label for="password" class="form-label">{{'password' | translate}}</label>
        <input type="password" id="password" class="form-control" formControlName="password" [ngClass]="{'is-invalid': resetPasswordFormControls.password.invalid &&
          (submitted || resetPasswordFormControls.password.touched)}" />
        <div *ngIf="
            resetPasswordFormControls.password.invalid &&
            (submitted || resetPasswordFormControls.password.touched)
          " class="invalid-feedback">
          <div *ngIf="resetPasswordFormControls.password.errors?.['required']">
            {{'passwordIsRequired' | translate}}
          </div>
          <div
            *ngIf="resetPasswordFormControls.password.errors?.['minlength'] || resetPasswordFormControls.password.errors?.['pattern']">
            {{'PasswordMustHave8OrMoreCharactersUpper&LowercaseLettersAndAtLeastOneNumber' | translate}}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">{{'confirmPassword' | translate}}</label>
        <input type="password" id="confirmPassword" class="form-control" formControlName="confirmPassword" [ngClass]="{'is-invalid': resetPasswordFormControls.confirmPassword.invalid &&
          (submitted || resetPasswordFormControls.confirmPassword.touched)}" />
        <div *ngIf="
            resetPasswordFormControls.confirmPassword.invalid &&
            (submitted || resetPasswordFormControls.confirmPassword.touched)
          " class="invalid-feedback">
          <div *ngIf="resetPasswordFormControls.confirmPassword.errors?.['required']">
            {{'confirmPasswordIsRequired' | translate}}
          </div>
          <div *ngIf="resetPasswordFormControls.confirmPassword.errors?.['mustMatch']">
            {{'passwordAndConfirmPasswordMustBeMatched' | translate}}
          </div>
        </div>
        <p class="fs-sm pt-1 text-secondary">
          {{'yourPasswordMustHave8OrMoreCharactersUpper&LowercaseLettersAtLeastOneNumberAndOneSpecialCharacter' |
          translate}}
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary px-5"> {{'submit' | translate}}</button>
      </div>
      <div class="text-center pt-3 mb-0">
        <a routerLink="/login">{{'backToSignIn' | translate}}</a>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>