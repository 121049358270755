import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesRoutingModule } from './pipes-routing.module';
import { TranslatePipe } from './translate/translate.pipe';
import { SanitizePipe } from './sanitize/sanitize.pipe';


@NgModule({
  declarations: [
    TranslatePipe,
    SanitizePipe
  ],
  imports: [
    CommonModule,
    PipesRoutingModule
  ],
  exports: [
    TranslatePipe,
    SanitizePipe
  ]
})
export class PipesModule { }
