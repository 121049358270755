<div class="common-card">
  <form [formGroup]="checkBoxForm">
    <div *ngFor="let item of data">
      <div *ngIf="item.entity.length > 0">
        <div class="d-flex justify-content-between w-85">
          <h2 class="mb-3 pb-1">{{ item.title }}</h2>
          <ng-content select=".ng-content"></ng-content>
          <label *ngIf="item.isRadio" class="dash-default">Set default</label>
        </div>
        <div class="checkbox-col d-flex flex-wrap">
          <div class="form-group form-check" formArrayName="dashboard"
            *ngFor="let control of getDashboard['controls']; let i = index">
            <div [formGroupName]="i" class="form-check-inner">
              <input type="checkbox" [id]="i" formControlName="checkbox" />
              <label [for]="i">
                {{ control.value.name }}
              </label>
              <input *ngIf="item.isRadio" class="ms-auto" type="radio" name="radio"
                (change)="check(control.value.id)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>