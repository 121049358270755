import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map } from 'rxjs';
import { FilterOptions } from 'src/app/shared/interfaces/common.interface';
import { ClientLabelPipe } from 'src/app/shared/pipes/client-label.pipe';
import { FilterService } from 'src/app/shared/services/filter.service';

/**
 * Component to handle filtering and searching within a dialog.
 */
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, AfterViewInit, OnDestroy {
  /** List of items to search through */
  @Input() searchList;
  /** Event emitted on key up in the search input */
  @Output() searchKeyUp = new EventEmitter();
  /** Event emitted when the clear button is pressed */
  @Output() clear: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  /** Reference to the search input element */
  @ViewChild('searchEvent') searchEvent!: ElementRef;
  /** Form group to handle the checkbox filter options */
  checkBoxForm!: FormGroup;
  /** List of filter options */
  filterOptions: Array<FilterOptions> = [];
  /** Search keyword */
  searchKey = '';
  /** Dialog title */
  title = '';
  /** Flag to show search input */
  showSearch = false;
  /** Flag to show filter options */
  showFilter = false;
  /** Flag to indicate if all options are selected */
  allSelected = false;
  /** Flag to indicate if the selection is indeterminate */
  indeterminate = false;
  /** Constant for zero */
  zero = 0;

  /**
   * Constructor to inject dependencies and initialize the component
   * @param data Data passed to the dialog
   * @param matDialogRef Reference to the dialog
   * @param fb FormBuilder service to create forms
   * @param clientLabelPipe Pipe to transform client labels
   * @param filterService Service to handle filtering
   * @param cdk ChangeDetectorRef to manually trigger change detection
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<FilterComponent>,
    private fb: FormBuilder,
    private clientLabelPipe: ClientLabelPipe,
    private filterService: FilterService,
    private cdk: ChangeDetectorRef,
  ) {}

  /**
   * OnInit lifecycle hook to initialize the component
   */
  ngOnInit(): void {
    if (this.data.type === 'search') {
      this.showSearch = true;
      this.title = this.data.title;
      console.log(this.data);
      if (this.data.search) {
        this.searchKey = this.data.search;
      }
    }
    if (this.data.type === 'filter') {
      this.checkBoxForm = this.fb.group({
        filterOptions: new FormArray([]),
      });
      this.showFilter = true;
      this.filterService.filterOptions.subscribe((response) => {
        this.filterOptions = response.map((o) => {
          let filterO;
          if (typeof o === 'string') {
            filterO = { id: o, name: this.getClientLabelByKey(o), checked: false };
          } else {
            filterO = { id: o.id, name: o.name, checked: false };
          }
          return filterO;
        });
        if (this.filterOptions?.length) {
          this.createFilterControl(this.filterOptions);
        }
      });
    }
  }

  /**
   * AfterViewInit lifecycle hook to set up the search key up event
   */
  ngAfterViewInit(): void {
    this.searchKeyUpMethod();
  }

  /**
   * Getter for the filter options form array
   */
  get options(): FormArray {
    return this.checkBoxForm.get('filterOptions') as FormArray;
  }

  /**
   * Searches based on the search key and closes the dialog
   */
  search() {
    this.matDialogRef.close(this.searchKey);
  }

  /**
   * Emits the clear event with the data
   */
  remove() {
    
    this.clear.emit(this.data);
  }

  /**
   * Sets up the search key up event to debounce and filter the input
   */
  searchKeyUpMethod() {
    fromEvent(this.searchEvent.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        map((event: any) => (<HTMLInputElement>event.target).value),
        filter((keywords: string) => keywords.length >= 3 || keywords.length === 0),
        distinctUntilChanged(),
      )
      .subscribe((keyword) => {
        if (this.data.type === 'search') {
          if (keyword) {
            this.searchKeyUp.emit(keyword);
          } else {
            this.searchList = [];
          }
        }
        if (this.data.type === 'filter') {
        }
      });
  }

  /**
   * Closes the dialog with the selected option
   * @param item Selected option
   */
  selectedOption(item) {
    this.matDialogRef.close(item);
  }

  /**
   * Creates a new filter options form group
   * @param option Filter option
   * @returns FormGroup with the filter option controls
   */
  newFilterOptions(option: FilterOptions): FormGroup {
    return this.fb.group({
      checked: new FormControl(option.checked),
      id: new FormControl(option.id),
      name: new FormControl(option.name),
    });
  }

  /**
   * Creates the filter controls for the options
   * @param options List of filter options
   */
  createFilterControl(options) {
    // const idArray=options.map(ele=>ele.id);
    //   var duplicates= idArray.some((item,index)=> {
    //     return idArray.indexOf(item)!==index
    //   });
    //   console.log(duplicates,'duplicates');
    if (options?.length) {
      (<FormArray>this.checkBoxForm.get('filterOptions')).controls = [];
      const appliedFilters = this.filterService.getAppliedFiltersByKey(this.data.column.filterKey);
      options.filter((option: FilterOptions) => {
        console.log(option);
        if (appliedFilters && appliedFilters?.length) {
          option.checked = appliedFilters.map((o: FilterOptions) => o.id).indexOf(option.id) >= 0 ? true : false;
        }
        (<FormArray>this.checkBoxForm.get('filterOptions')).push(this.newFilterOptions(option));
      });
      this.allSelected =
        appliedFilters &&
        options.map((o: FilterOptions) => o.id).length === appliedFilters.map((o: FilterOptions) => o.id).length
          ? true
          : false;

      this.indeterminate =
        appliedFilters && appliedFilters.map((o: FilterOptions) => o.id).length > this.zero ? true : false;
    }
  }

  /**
   * Handles the change event of the checkboxes to update the selection flags
   * @param eve Change event
   */
  onChangeCheckBox(eve) {
    // console.log(this.checkBoxForm.value);
    if (
      this.checkBoxForm.value.filterOptions.filter((fOption) => fOption.checked === true).length ===
      this.filterOptions.length
    ) {
      this.allSelected = true;
      if (eve.target.checked) {
        this.indeterminate = false;
      } else {
        this.indeterminate = true;
      }
      this.cdk.detectChanges();
    } else {
      if (eve.target.checked) {
        this.indeterminate = true;
      } else {
        if (this.checkBoxForm.value.filterOptions.filter((fOption) => fOption.checked === true).length === this.zero) {
          this.indeterminate = false;
        } else {
          this.indeterminate = true;
        }
      }
      this.allSelected = false;
      this.cdk.detectChanges();
    }
  }

  /**
   * Selects or deselects all options
   * @param eve Change event
   */
  selectAllOptions(eve) {
    const control = <FormArray>this.checkBoxForm.controls['filterOptions'];
    control.controls.map((value) => value.get('checked')?.patchValue(eve.checked));
    this.allSelected = true;
  }

  /**
   * Transforms a label using the ClientLabelPipe
   * @param label Label to transform
   * @returns Transformed label
   */
  getClientLabelByKey(label: string) {
    return this.clientLabelPipe.transform(label);
  }

  /**
   * Gets the API processing flag from the filter service
   * @returns API processing flag
   */
  getAPIProcessingFlag() {
    return this.filterService.apiIsProcessing;
  }

  /**
   * Saves the selected filter options and closes the dialog
   */
  save() {
    const selectedOptions = this.checkBoxForm.value.filterOptions.filter((ele: FilterOptions) => ele.checked === true);
    this.matDialogRef.close(selectedOptions);
  }

  /**
   * OnDestroy lifecycle hook to reset the filter options in the filter service
   */
  ngOnDestroy() {
    this.filterService.filterOptions.next([]);
  }
}
