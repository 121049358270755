<div>
  <div class="form-group position-relative resources-tag">
    <label for="" class="form-label">{{ label }}</label>
    @if(showTypeSearchBox) {
      <input type="text" #typeaheadsearch class="form-control" placeholder="{{ placeholder }}" id="typeaheadsearch"
      autocomplete="off" [value]="selectedItems" [matAutocomplete]="auto" [matAutocompletePosition]="'below'" [ngClass]="{
        'is-invalid': form?.controls?.[formControlKey]?.invalid && (isSubmitted || form?.controls?.[formControlKey]?.dirty || form?.controls?.[formControlKey]?.touched)
      }" />
    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="'auto'" class="custom-typeahead"
      classList="custom-typeahead">
      <mat-option *ngFor="let listItem of data" [value]="listItem.name" (click)="onSelectItem(listItem)">
        {{listItem.name}}
      </mat-option>
    </mat-autocomplete>
    <i-feather name="search" class="feather-20 text-disabled"></i-feather>
    <div
      *ngIf="form?.controls?.[formControlKey]?.invalid && (isSubmitted || form?.controls?.[formControlKey]?.dirty || form?.controls?.[formControlKey]?.touched)"
      class="invalid-feedback">
      <div *ngIf="form?.controls?.[formControlKey]?.errors?.['required']">This field is required.</div>
    </div>
    }
  </div>
  <div class="resource-clip" appearance="fill"
    *ngIf="isArray(selectedItems) && selectedItems && selectedItems.length > 0">
    <div #chipList>
      <mat-chip-option class="clip-Prog" *ngFor="let item of selectedItems">
        {{ item }}
        @if(showTypeSearchBox) {
          <button matChipRemove (click)="removeSelectedItem(item)">
            <i-feather name="x-circle" class="feather-20"></i-feather>
          </button>
        }
      </mat-chip-option>
    </div>
  </div>
</div>