import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/shared/validators/must-match.validators';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';
import { AuthenticationService } from '../../authentication.service';
/**
 * Component for handling password reset functionality.
 * Provides a form for users to set a new password using a temporary code from the reset email.
 */
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  /**
   * Form group for the reset password form.
   *
   * @type {FormGroup}
   */
  resetPasswordForm!: FormGroup;

  /**
   * Flag to indicate if the form has been submitted.
   *
   * @type {boolean}
   */
  submitted = false;

  /**
   * Temporary code used to verify the password reset request.
   *
   * @type {string}
   */
  token = '';

  /**
   * Constructs an instance of ResetPasswordComponent.
   *
   * @param fb - FormBuilder service for creating and managing forms.
   * @param _authService - AuthenticationService for handling authentication requests.
   * @param activateRoute - ActivatedRoute service for accessing route parameters.
   * @param toastrService - ToastrService for displaying notifications.
   */
  constructor(
    private fb: FormBuilder,
    private _authService: AuthenticationService,
    private activateRoute: ActivatedRoute,
    private toastrService: ToastrService,
  ) {}

  /**
   * Initializes the component by setting up the reset password form and retrieving the temporary code.
   */
  ngOnInit(): void {
    this.token = this.activateRoute.snapshot.queryParamMap.get('tempCode') || '';
    this.resetPasswordForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(8), PasswordStrengthValidator()]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      },
    );
  }

  /**
   * Gets the form controls of the reset password form.
   *
   * @returns {any} The controls of the reset password form.
   */
  get resetPasswordFormControls() {
    return this.resetPasswordForm['controls'];
  }

  /**
   * Submits the reset password form.
   * If the form is valid and the token is present, it calls the resetPassword method of AuthenticationService
   * to update the password. Displays a success message upon successful password change.
   *
   * @memberof ResetPasswordComponent
   */
  submit() {
    this.submitted = true;
    if (this.resetPasswordForm.valid && this.token) {
      const postO = {
        password: this.resetPasswordForm.value.password,
        code: this.token,
      };
      this._authService.resetPassword(postO).subscribe(
        (response) => {
          if (response) {
            this.toastrService.success('Password has been changed successfully');
          }
        },
        (error) => {
          // if (error) {
          //   console.log(error);
          // }
        },
      );
    }
  }
}
