import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Directive that trims whitespace from text input fields.
 *
 * This directive listens for keyup and blur events on text input fields
 * and trims leading and trailing whitespace from the input value. It also
 * collapses multiple spaces into a single space for non-numeric values.
 *
 * The directive is applicable to input fields with the following selectors:
 * - `input[type=text][formControlName]`
 * - `input[type=text][formControl]`
 * - `input[type=text][ngModel]`
 */
@Directive({
  selector: 'input[type=text][formControlName], input[type=text][formControl], input[type=text][ngModel]',
})
export class TrimDirective {
  /**
   * Creates an instance of the TrimDirective.
   *
   * @param control An instance of `NgControl` used to access and modify the value
   * of the form control associated with the input field.
   */
  constructor(private control: NgControl) {}

  /**
   * Listens to the `keyup` event on the input field and trims whitespace.
   *
   * Removes extra spaces and collapses multiple spaces into a single space
   * for non-numeric values. Does not modify numeric values.
   *
   * @param e The keyboard event triggered by the user.
   */
  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent): any {
    if (isNaN(parseInt(this.control?.control?.value))) {
      this.control?.control?.setValue(
        this.control?.control?.value?.trim() === '' ? '' : this.control?.control?.value?.replace(/\s\s+/g, ' '),
      );
    }
  }

  /**
   * Listens to the `blur` event on the input field and trims whitespace.
   *
   * Trims leading and trailing whitespace from the input value when the input field
   * loses focus, but does not modify numeric values.
   *
   * @param e The keyboard event triggered by the user.
   */
  @HostListener('blur', ['$event'])
  onBlur(e: KeyboardEvent): any {
    // console.log('called from blur')
    if (isNaN(this.control?.control?.value)) {
      this.control?.control?.setValue(this.control?.control?.value?.trim());
    }
  }
}
