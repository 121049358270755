<div class="add-medication-container modal-xs">
    <div class="flex-between dialog-header">
        <h2>Search</h2>
        <div class="dialog-header-icons">
            <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
        </div>
    </div>
    <mat-dialog-content>
        <div class="add-medication-search">
            <div class="form-group search-input search-100">

                <input type="text" class="form-control" />
                <i-feather name="search" class="feather-20 text-disabled"></i-feather>
            </div>
        </div>
        <div class="mt-2">
            <label>Template 1</label>
            <label>Template 1</label>
        </div>
    </mat-dialog-content>
</div>