<div class="auth-wrapper p-3">
  <div class="logo text-center">
    <img src="assets/images/logo/medocity_logo.png" alt="Medocity logo" />
  </div>
  <div class="auth-container bg-white radius-md">
    <h1>{{'signIn' | translate}}</h1> 
    <form [formGroup]="loginForm" (ngSubmit)="submit()">
      <div class="mb-3">
        <label for="userName" class="form-label">{{'username' | translate}}</label>
        <input type="email" id="userName" class="form-control" formControlName="username" [ngClass]="{
            'is-invalid':
              loginFormControls.username.invalid &&
              (submitted || loginFormControls.username.touched)
          }" />
        <div *ngIf="
            loginFormControls.username.invalid &&
            (submitted || loginFormControls.username.touched)
          " class="invalid-feedback">
          <div *ngIf="loginFormControls.username.errors?.['required']">
            Username is required.
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-flex justify-content-between">
          <label for="paswword" class="form-label">{{'password' | translate}}</label>
          <a routerLink="/forgot-password" class="text-decoration-none">{{'forgotPassword?' | translate}}</a>
        </div>
        <input type="password" id="paswword" class="form-control" formControlName="password" [ngClass]="{
            'is-invalid':
              loginFormControls.password.invalid &&
              (submitted || loginFormControls.password.touched)
          }" />
        <div *ngIf="
            loginFormControls.password.invalid &&
            (submitted || loginFormControls.password.touched)
          " class="invalid-feedback">
          <div *ngIf="loginFormControls.password.errors?.['required']">
            {{'passwordIsRequired' | translate}}
          </div>
          <div *ngIf="loginFormControls.password.errors?.['minlength']">
            Password should be more than 8 characters.
            {{'passwordShouldBeMoreThan8Characters' | translate}}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary">
          {{'signIn' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>