import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
/**
 * Service for handling authentication-related operations such as login, logout, and token management.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /**
   * Interval ID for the refresh token timer.
   * This ID is used to clear the interval when necessary.
   */
  refreshTokenTimerInterval!: ReturnType<typeof setInterval>;

  /**
   * Constructs an instance of AuthenticationService.
   *
   * @param authUrl - Base URL for authentication API.
   * @param apiUrl - Base URL for general API.
   * @param apiVersion - API version to use.
   * @param httpService - Service for making HTTP requests.
   * @param router - Router service for navigation.
   */
  constructor(
    @Inject('AUTH_API_BASE') private authUrl: string,
    @Inject('API_BASE') private apiUrl: string,
    @Inject('API_VERSION') private apiVersion,
    private httpService: HttpService,
    private router: Router,
  ) {}

  /**
   * Logs in a user.
   *
   * @param body - The login credentials.
   * @returns Observable with the login response.
   */
  login(body): Observable<unknown> {
    const url = `${this.authUrl}/auth/platform-login`;
    //const url = `${this.authUrl}/auth/application-login`;
    return this.httpService.post(url, body);
  }

  /**
   * Initiates a password reset process.
   *
   * @param body - The user information for password reset.
   * @returns Observable with the response of the password reset request.
   */
  forgotPassword(body): Observable<unknown> {
    const url = `${this.apiUrl}/${this.apiVersion}/auth/forgotPassword`;
    return this.httpService.post(url, body);
  }

  /**
   * Resets the user's password.
   *
   * @param body - The new password information.
   * @returns Observable with the response of the password reset request.
   */
  resetPassword(body): Observable<unknown> {
    const url = `${this.apiUrl}/${this.apiVersion}/auth/resetPassword`;
    return this.httpService.patch(url, body);
  }

  /**
   * Refreshes the authentication token.
   *
   * @param body - The refresh token request body.
   * @param headers - Optional HTTP headers.
   * @returns Observable with the response of the refresh token request.
   */
  refreshToken(body, headers) {
    const url = `${this.authUrl}/auth/token`;
    return this.httpService.post(url, body, headers);
  }

  /**
   * Switches the client by ID.
   *
   * @param clientId - The client ID to switch to.
   * @returns Observable with the response of the client switch request.
   */
  switchClientById(clientId: string) {
    const url = `${this.authUrl}/auth/request-client-token/${clientId}`;
    const headers = new HttpHeaders({
      'client-token': this.getClientToken() || '',
    });
    return this.httpService.post(url, {}, { headers: headers });
  }

  /**
   * Logs out the user.
   *
   * @returns Observable with the response of the logout request.
   */
  logout() {
    const url = `${this.authUrl}/auth/logout`;
    return this.httpService.get(url);
  }

  /**
   * Sets authentication tokens in local storage.
   *
   * @param tokens - The tokens to be set.
   */
  setTokens(tokens) {
    if (tokens) {
      this.removeTokens();
      localStorage.setItem('access_token', tokens.access_token ? tokens.access_token : '');
      localStorage.setItem('refresh_token', tokens.refresh_token ? tokens.refresh_token : '');
      localStorage.setItem('clientToken', tokens.clientToken ? tokens.clientToken : '');
      localStorage.setItem(
        'access_token_expires_in_minutes',
        tokens.access_token_expires_in_minutes ? tokens.access_token_expires_in_minutes : '',
      );
    }
  }

  /**
   * Sets user details in local storage.
   *
   * @param user - The user details to be set.
   */
  setUserDetails(user) {
    localStorage.setItem('userDetails', user ? JSON.stringify(user) : '');
  }

  /**
   * Gets user details from local storage.
   *
   * @returns The user details, or null if not set.
   */
  getUserDetails() {
    if (localStorage.getItem('userDetails')) {
      const userDetails = localStorage.getItem('userDetails');
      return userDetails ? JSON.parse(userDetails) : null;
    }
  }

  /**
   * Sets the preferred language in local storage.
   *
   * @param lang - The language code, default is 'en'.
   */
  setLanguage(lang: string = 'en') {
    localStorage.setItem('NG_TRANSLATE_LANG_KEY', lang);
  }

  /**
   * Gets the preferred language from local storage.
   *
   * @returns The language code, default is 'en'.
   */
  getLanguage() {
    return localStorage.getItem('NG_TRANSLATE_LANG_KEY') || 'en';
  }

  /**
   * Gets the access token from local storage.
   *
   * @returns The access token, or an empty string if not set.
   */
  geToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  /**
   * Gets the client token from local storage.
   *
   * @returns The client token, or an empty string if not set.
   */
  getClientToken(): string {
    return localStorage.getItem('clientToken') || '';
  }

  /**
   * Removes all authentication tokens from local storage.
   */
  removeTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('clientToken');
    localStorage.removeItem('refresh_token_timer_in_seconds');
  }

  /**
   * Signs out the user by clearing local storage and navigating to the login page.
   */
  signOut() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  /**
   * Checks if the user is authorized by verifying the presence of an access token.
   *
   * @returns True if authorized, otherwise false.
   */
  isAuthorized() {
    return this.geToken() ? true : false;
  }

  /**
   * Starts a timer to refresh the token before it expires.
   */
  startRefreshTokenTimer() {
    this.refreshTokenTimerInterval = setInterval(() => {
      if (this.isAuthorized()) {
        const refresh_token_timer_in_seconds = Number(localStorage.getItem('refresh_token_timer_in_seconds'));
        const access_token_expires_in_minutes = Number(localStorage.getItem('access_token_expires_in_minutes')) * 60;
        let timer =
          refresh_token_timer_in_seconds > 0 ? refresh_token_timer_in_seconds : access_token_expires_in_minutes;
        timer = timer - 1;
        localStorage.setItem('refresh_token_timer_in_seconds', String(timer));
        if (timer <= 60) {
          this.fetchRefreshToken();
          this.clearRefreshTokenTimerInterval();
        }
      }
    }, 1000);
  }

  /**
   * Clears the refresh token timer interval.
   */
  clearRefreshTokenTimerInterval() {
    if (this.refreshTokenTimerInterval) {
      clearInterval(this.refreshTokenTimerInterval);
    }
  }

  /**
   * Fetches a new refresh token and updates tokens in local storage.
   */
  fetchRefreshToken() {
    const body = new URLSearchParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const refresh_token = localStorage.getItem('refresh_token') || '';
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh_token);
    if (refresh_token) {
      this.refreshToken(body, { headers: headers }).subscribe(
        (response) => {
          this.clearRefreshTokenTimerInterval();
          this.startRefreshTokenTimer();
          this.setTokens(response);
        },
        (error) => {
          if (error) {
            // this.toastr.error(error.error.message, error.error.error);
            //this.signOut();
          }
        },
      );
    }
  }
}
