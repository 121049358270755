import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Directive to detect clicks outside of a specified element.
 *
 * This directive emits an event whenever a click is detected outside the element to which it is applied.
 * It is useful for handling interactions such as closing dropdowns or modals when the user clicks outside.
 */
@Directive({
  selector: '[clickOutSide]',
})
export class ClickOutSideDirective implements AfterViewInit, OnDestroy {
  /**
   * Emits an event when a click outside the element is detected.
   */
  @Output() clickedOutSide = new EventEmitter<void>();

  /**
   * Subscription for tracking clicks outside of the component.
   */
  outsideClickSubscription!: Subscription;

  /**
   * Creates an instance of ClickOutSideDirective.
   *
   * @param element The element to check for clicks outside of.
   * @param document The document object to listen for click events.
   */
  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  /**
   * Sets up the click event listener after the view is initialized.
   */
  ngAfterViewInit(): void {
    this.outsideClickSubscription = fromEvent(this.document, 'click')
      .pipe(
        filter((elem: any) => {
          return !this.isInside(elem?.target as HTMLElement);
        }),
      )
      .subscribe(() => {
        this.clickedOutSide.emit();
      });
  }

  /**
   * Checks if the click event happened inside the element or its children.
   *
   * @param elementToCheck The element to check.
   * @returns `true` if the click happened inside the element, `false` otherwise.
   */
  isInside(elementToCheck: HTMLElement): boolean {
    return (
      elementToCheck === this.element.nativeElement ||
      this.element.nativeElement.contains(elementToCheck) ||
      elementToCheck?.id === 'typeaheadsearch'
    );
  }

  /**
   * Cleans up the subscription when the directive is destroyed.
   */
  ngOnDestroy() {
    this.outsideClickSubscription.unsubscribe();
  }
}
